import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Store from './Redux';
import { CheckAuth } from './Redux/Actions';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'Helpers/ScrollToTop';

Store.dispatch(CheckAuth());
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={Store}>
    <Router>
    <ScrollToTop/>
      <App />
    </Router>
  </Provider>

);

reportWebVitals();
