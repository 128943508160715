export const colors = {
    white:'#ffffff',
    black:'#000000',
    blue:'#520EE2',
    purple: '#A925E0',
    btnColor2:'#E334CE',
    darkPurple: '#514B78', //primary color
    darkV:'#110D32',
    darkgray:'#000000e0',
    gray:{
        50: '#fafafa',
        100:'#00000073',
        200:'#728390' ,//dashboard cardd
    },
    danger:'#EE4B2B'
}

