import { ActivityMenu, ProfileMenu, SettengsMenu } from "Helpers/icons";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PeopleIcon from '@mui/icons-material/People';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';

export class Menu {
    public title!: string;
    public pathname!: string;
    public icon!: any;
}

export const SideMenu: Array<Menu> = [
    { title: 'Activity', pathname: '/activity', icon: ActivityMenu },
    { title: 'Profile', pathname: '/profile', icon: ProfileMenu },
    { title: 'Plans', pathname: '/plans', icon: AttachMoneyOutlinedIcon },
    { title: 'Videos', pathname: '/videos', icon: VideocamIcon}
]

export const AadminSideMenu: Array<Menu> = [
    { title: 'Users', pathname: '/users', icon: PeopleIcon },
    { title: 'Report', pathname: '/report', icon: SummarizeOutlinedIcon },
    { title: 'Plans', pathname: '/plans', icon: AttachMoneyOutlinedIcon },
    { title: 'Settings', pathname: '/settings', icon: SettengsMenu },
]
