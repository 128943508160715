import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Map } from "Helpers/Array";
import { Props, Response } from 'Redux/Models';
import { RouteInterface } from "Routes";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { DashboardLogo } from 'Helpers/image';
import { MuiAvatar, MuiBox, MuiButton, MuiIconButton, MuiTypography } from 'Components/MUI';
import { colors } from 'theme/color';
import MuiTextField from 'Components/MUI/MuiTextField';
import { ChanelIcon, InputSearchIcon, LogoutIcon, NotificationBell, UploadBtnIcon } from 'Helpers/icons';
import { AadminSideMenu, SideMenu, userTypes } from 'Configs';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { AuthService } from 'Services';
import { getImage, getUserType } from 'Helpers';
import { ChannelSwitch, LoadUser } from 'Redux/Actions';
import { Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationBar from 'Components/NotificationBar';
import ChannelList from 'Components/channelList';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


//Tooltip custome styling------------->
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#520EE2 !important',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    fontSize: 12,
  },
}));

const drawerWidth = 214;

function RouteToRedirect(props: Props) {
  const { route, ...restProps } = props

  useEffect(() => {
    props.setTitle(route.name)
  }, [props, route])

  return <>
    <route.component
      {...restProps}
      title={route.name}
      childrens={route.childrens}
    />
  </>
}


function DashboardLayout(props: Props) {
  const { childrens, ...restProps } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [mobileOpen, SetmobileOpen] = useState(false);
  const [searchField, SetSearchField] = useState(false);
  const [title, setTitle] = useState('')
  const open2 = Boolean(anchorEl2);
  const open = Boolean(anchorEl);
  const { user } = props

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const notiHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const notiHandleClose = () => {
    setAnchorEl2(null);
  };

  const fetUserData = () => {
    AuthService.getMe().then((resp) => {
      const user = resp.data.user
      props.dispatch(LoadUser(user))
      if (user.channels && user.channels.length) { //as channel data is not available at login api
        props.dispatch(ChannelSwitch(user.channels[0]))
      }

    }).catch((err) => {
      // 
    })
  }

  useEffect(() => {
    fetUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const performLogout = () => {
    props.dispatch(AuthService.logOut())
      .then((resp: Response<any>) => {
        if (resp.isSuccess()) {
          //
        }
      }).catch((err: any) => {
        // 
      })
  }

  const goToUpload = () => {
    props.history?.push('/upload-video');
  }

  const toggleDrawer = () => {
    SetmobileOpen(!mobileOpen);
  }

  const toggleSearchBtn = () => {
    SetSearchField(!searchField);
  }

  const goToProfile = () => {
    props.history?.push('/profile');
  }


  return (
    <Box sx={{ display: 'flex' }} className='full_body_wrap'>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
        className='clint_app_header'
      >
        <Toolbar className="app_navbar">
          <MuiTypography component='div' className='page_name' color={colors.black}>
            {title}
          </MuiTypography>

          {getUserType(user.role_type) !== userTypes.ADMIN &&
            <MuiBox className={searchField ? 'active-nav_search_field nav_search_field' : 'nav_search_field'}>
              <MuiTextField
                placeholder='Search'
                starticon={<InputSearchIcon />}
              />
            </MuiBox>
          }
          <MuiBox className='nav_user_menu'>
            <MuiIconButton className='nav_search_btn' onClick={toggleSearchBtn}>
              <InputSearchIcon />
            </MuiIconButton>

            {/* Only admin can see this --------> */}
            {getUserType(user.role_type) !== userTypes.ADMIN &&
              (user && user.channels.length > 0) &&
              <>

                {props.user.subscription && <>
                  <MuiButton onClick={goToUpload} className='desktop_view_uploadbtn'><UploadBtnIcon /> Upload video</MuiButton>

                  <LightTooltip title="Upload vidos">
                    <MuiIconButton className='icon_button mobile_view_uploadbtn' onClick={goToUpload}>
                      <NotificationBell />
                    </MuiIconButton>
                  </LightTooltip>
                </>
                }
                {user?.channels?.length &&
                  <LightTooltip title="Channels Name">
                    <MuiIconButton
                      className='icon_button chanel_btn'
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <ChanelIcon />
                    </MuiIconButton>
                  </LightTooltip>
                }

                <ChannelList open={open} onClose={handleClose} anchorEl={anchorEl} {...props} />
              </>
            }

            {/* <LightTooltip title="Notifications">
              <MuiIconButton className='nav_notification_btn' onClick={notiHandleClick}>
                <Badge color="secondary" badgeContent={5}>
                  {open2 ? <NotificationsIcon /> : <NotificationsNoneOutlinedIcon />}
                </Badge>
              </MuiIconButton>
            </LightTooltip> */}

            <LightTooltip title="Profile">
              <MuiAvatar alt={user.name} src={getImage(user.profile_image)}
                onClick={goToProfile}
                sx={{ cursor: 'pointer' }}
                className='dashboard_profile_hover'
              />
            </LightTooltip>
          </MuiBox>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          border: 'none',
          width: drawerWidth,
          flexShrink: 0,
          position: "relative",
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            border: 'none',
            backgroundColor: 'transparent'
          },
        }}
        variant="permanent"
        anchor="left"
        className='mui_app_drawer'
      >
        <Toolbar className='mui_tool_bar'>
          <img src={DashboardLogo} alt='app logo' />
        </Toolbar>
        {getUserType(user.role_type) !== userTypes.ADMIN &&
          <List sx={{ marginTop: '38px' }}>
            {SideMenu.map((e, i) => (
              <ListItem key={i} disablePadding className='menu_list'>
                <NavLink
                  className='link_menu'
                  activeClassName='activeNavMenu'
                  isActive={(match, location) => {
                    if (location.pathname.startsWith(e.pathname)) {
                      return true;
                    }
                    return false;
                  }}
                  exact={true}
                  to={e.pathname}
                >
                  <ListItemButton>
                    <ListItemIcon className='menu_icon_color'>
                      <e.icon />
                    </ListItemIcon>
                    <ListItemText primary={e.title} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
          </List>
        }

        {getUserType(user.role_type) === userTypes.ADMIN &&
          <List>
            {AadminSideMenu.map((e, i) => (
              <ListItem key={i} disablePadding className='menu_list'>
                <NavLink
                  className='link_menu'
                  activeClassName='activeNavMenu'
                  isActive={(match, location) => {
                    if (location.pathname.startsWith(e.pathname)) {
                      return true;
                    }
                    return false;
                  }}
                  exact={true}
                  to={e.pathname}
                >
                  <ListItemButton>
                    <ListItemIcon className='menu_icon_color'>
                      <e.icon />
                    </ListItemIcon>
                    <ListItemText primary={e.title} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
          </List>
        }
        <MuiBox className="logout_button">
          <MuiButton onClick={() => performLogout()}>
            <img src={LogoutIcon} alt='' />
            Log out
          </MuiButton>
        </MuiBox>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className='box_main_section'
      >

        <Switch>

          {Map(childrens, (route: RouteInterface, i) => {
            return (
              <Route
                key={i}
                exact={route.exact}
                path={route.path}
                render={(rProps: any) =>
                  !route.redirect ? (
                    <RouteToRedirect route={route} setTitle={setTitle} restProps={restProps} {...props} {...rProps} />
                  ) : (
                    <Redirect to={route.redirect || "/"} />
                  )
                }
              />
            );
          })}

        </Switch>
      </Box>
      <MuiBox sx={{ width: '20px' }}>

      </MuiBox>
      <NotificationBar NotiOpen={open2} onClose={notiHandleClose} anchorEl2={anchorEl2} />
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
      >
        <Toolbar className='mui_tool_bar'>
          <img src={DashboardLogo} alt='app logo' />
        </Toolbar>
        {getUserType(user.role_type) !== userTypes.ADMIN &&
          <List>
            {SideMenu.map((e, i) => (
              <ListItem key={i} disablePadding className='menu_list' onClick={toggleDrawer} >
                <NavLink
                  className='link_menu'
                  activeClassName='activeNavMenu'
                  isActive={(match, location) => {
                    if (location.pathname.startsWith(e.pathname)) {
                      return true;
                    }
                    return false;
                  }}
                  exact={true}
                  to={e.pathname}
                >
                  <ListItemButton>
                    <ListItemIcon className='menu_icon_color'>
                      <e.icon />
                    </ListItemIcon>
                    <ListItemText primary={e.title} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
          </List>
        }

        {getUserType(user.role_type) === userTypes.ADMIN &&
          <List>
            {AadminSideMenu.map((e, i) => (
              <ListItem key={i} disablePadding className='menu_list' onClick={toggleDrawer} >
                <NavLink
                  className='link_menu'
                  activeClassName='activeNavMenu'
                  isActive={(match, location) => {
                    if (location.pathname.startsWith(e.pathname)) {
                      return true;
                    }
                    return false;
                  }}
                  exact={true}
                  to={e.pathname}
                >
                  <ListItemButton>
                    <ListItemIcon className='menu_icon_color'>
                      <e.icon />
                    </ListItemIcon>
                    <ListItemText primary={e.title} />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            ))}
          </List>
        }
        <MuiBox className="logout_button">
          <MuiButton onClick={() => performLogout()}>
            <img src={LogoutIcon} alt='' />
            Log out
          </MuiButton>
        </MuiBox>
        <MuiIconButton onClick={toggleDrawer} className='mobile_menu_button close_mobile_icon'>
          <KeyboardDoubleArrowLeftIcon />
        </MuiIconButton>
      </Drawer>
      <MuiIconButton onClick={toggleDrawer} className='mobile_menu_button'>
        <KeyboardDoubleArrowRightIcon />
      </MuiIconButton>
    </Box>


  );
}

export default DashboardLayout