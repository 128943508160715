import Reducers from './Reducers';
import Stroage from './Storage';
import { configureStore } from '@reduxjs/toolkit';

const Store = configureStore({
	reducer: Reducers,
	preloadedState: Stroage.LoadState(),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false
		})
});

Store.subscribe(() => {
	Stroage.SaveState(Store.getState() as any);
});

export default Store;
