import { Menu, MenuItem } from '@mui/material'
import { MuiBox, MuiTypography } from './MUI';
import { colors } from 'theme/color';
import { useEffect, useState } from 'react';
import YoutubeService from 'Services/YoutubeService';
import { Map } from 'Helpers/Array';
import { ChannelModel, Props } from 'Redux/Models';
import { ChannelSwitch } from 'Redux/Actions';
import { UserData } from 'Configs/types';

interface ModalProps extends Props {
    open: boolean;
    onClose: () => void;
    anchorEl?: any;
}
const ChannelList: React.FC<ModalProps> = ({ open, onClose, anchorEl, ...props }) => {
    const [channelList, setChannelList] = useState<ChannelModel[]>([])

    const switchChannel = (channel: ChannelModel) => {
        props.dispatch(ChannelSwitch(channel))
        onClose()
    }

    const fetchVideoList = () => {
        YoutubeService.chanelList().then((resp) => {
            if (resp.isSuccess()) {
                const { channels } = resp.data
                setChannelList(channels);
            }
        }).catch(() => console.log('error'))
    }


    useEffect(() => {
        fetchVideoList()
    }, [])
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
                style: {
                    width: 300,
                    borderRadius: '10px'
                },
            }}
        >
            {Map(channelList, (item: UserData, index: number) => {
                return (
                    <MenuItem onClick={() => switchChannel(item)} key={index}>
                        <MuiBox className='menu_chanel_names'>
                            <MuiBox className='chanel_logo'>
                                <img src={item.high_thumbnail} alt={item.name} />
                            </MuiBox>
                            <MuiTypography color={colors.darkPurple}>{item.name}</MuiTypography>
                        </MuiBox>
                    </MenuItem>
                )
            })}
        </Menu>
    )
}

export default ChannelList;