import EditNoteIcon from '@mui/icons-material/EditNote';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export enum userTypes{
    "ADMIN",
    "USER"
}

export const VIDEO_TYPES={
    DEFAULT:"DEFAULT",
    SPECIALIZED:"SPECIALIZED"
}


export const ageRestriction=[
    {
        label:"For all",
        value:"1"
    },
    {
        label:"For Adults only",
        value:"0"
    }
]

export const genreSettings=[
    {
        name:"Private"
    },
    {
        name:"Public"
    }
]

export const overlap=[

]

export const viewerOverlap=[
    
]

export const Gender=[
    {
        name:"Male",
        value:"Male"
    },
    {
        name:"Female",
        value:'Female'
    }
]

export const InitialValSpecialVideo = {
    title: '',
    description: '',
    video: null,
    thumbnail: null,
    is_made_for_kids: '',
    country: '',
    genre: '',
    gender: '',
    start_time_min: '',
    start_time_sec: '',
    age_limit_start: '',
    age_limit_end: '',
    avg_watch_time_min: '',
    avg_watch_time_sec: '',
    overlap:''
}

export const SCREEN = {
    DEFAULT_VIDEO: 'DEFAULT_VIDEO',
    SPECIAL_VIDEO: 'SPECIAL_VIDEO',
    SHOW_VIDEO: 'SHOW_VIDEO',
};
export const Interval = [
    {
        name: "Monthly",
        value: "month"
    },
    {
        name: "Yearly",
        value: "year"
    }
]

export const ActionTypes={
    ADD:'ADD',
    EDIT:'EDIT',
    DELETE:'DELETE',
    VIEW:'VIEW',
    DOWNLOAD:'DOWNLOAD'
}

export const DataTableAction={
    EDIT:{type:ActionTypes.EDIT,icon:EditNoteIcon,tooltip:'edit'},
    DOWNLOAD:{type:ActionTypes.DOWNLOAD,icon:FileDownloadOutlinedIcon,tooltip:'Download'},

}
