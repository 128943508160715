import Configs, { userTypes } from "Configs"
import Visa from "images/visaCard.png"
import MasterCard from "images/masterCard.png"
import Rupay from "images/rupayCard.png"
import AmericanExpressCard from "images/americanExpress.png"
import DefaultCard from "images/defaultCard.png"
export const getUserType = (n: string) => {
    if (parseInt(n) === 1)
        return userTypes.ADMIN
    else if (parseInt(n) === 2)
        return userTypes.USER
    return ""
}

export const getImage = (link: string | undefined) => {
    if (link)
        return Configs.API_ROOT + "/" + link
    return ""
}

export const cardImage=(type:string)=>{
    switch (type.toLowerCase()) {
        case 'visa':
            return Visa
        case 'mastercard':
            return MasterCard
        case 'rupay':
            return Rupay
        case 'amex':
            return AmericanExpressCard
        default:
            return DefaultCard
    }
}

export const validTime=(e:any,fn:Function,minVal:number=0,maxVal:number=60)=>{
    const value=e.target.value
    if((Number(value)>minVal && Number(value)<maxVal) || value==='')
        fn(e)
}

export const onlyNumber = (event: any) => {
	if (!event) return '';

	const allowedKeys = [8, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];

	const { keyCode } = event;

	if (!allowedKeys.includes(keyCode)) {
		event.preventDefault();
		return;
	}
}