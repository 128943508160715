import {Location,History } from 'history';
import {AuthUserModel,UserModel} from "./User.Models"
import { ChannelModel } from './Channel.Models';
import { PlanModel } from './Plan.Models';
export {
    StateModel,
    DataTableActionModel,
  DataTableHeaderModel,
  DataTableModel,
} from "./Base.Models"


export{
    AuthUserModel,
    UserModel,
    ChannelModel,
    PlanModel
}
export class Props <T= unknown > {
    public user!: AuthUserModel;
    public title?: string;
    public history!: History<T>;
    public location!: Location<T>;
    public dispatch!: Function;
    public deviceToken!: string;
    [key: string]: any;
  }


export class States {
    [key: string]: any;
}

export class Response<T extends {}> {
  public data!: T;
  public status!: number;
  public message!: string;
  public success!: boolean;
  public constructor(params = {}) {
    Object.assign(this, params);
  }

  public getStatus(): number {
    return this.status;
  }

  public isSuccess(): boolean {
    return this.success;
  }

  public getData(): T {
    return this.data;
  }
}

  