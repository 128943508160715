import { Menu } from '@mui/material'
import { MuiBox, MuiButton, MuiTypography } from './MUI';
import Thumb from '../images/thumbnail.png'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
interface ModalProps {
    NotiOpen: boolean;
    onClose?: () => void;
    anchorEl2?: any;
}

function NotificationBar({ NotiOpen, onClose, anchorEl2 }: ModalProps) {
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl2}
            open={NotiOpen}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
                style: {
                    width: 550,
                    borderRadius: '15px',
                    padding: '10px',
                },
            }}
            sx={{top: "15px !important"}}
        >

            <MuiBox className='noti_modal_body_wrap'>
                <MuiBox className='noti_modal_head'>
                    <MuiTypography component='h3'>Notification</MuiTypography>
                    <MuiButton>Make as read</MuiButton>
                </MuiBox>

                <MuiBox className='notification_box'>
                    <MuiBox className='notification'>
                        <MuiBox className='noti_thumbnel'>
                            <img src={Thumb} alt="" />
                        </MuiBox>
                        <MuiBox className='noti_body'>
                            <MuiTypography className='video_name'>Hey Mor (Lyric) - Ozuna / Bad Bunny, Metro Boomin</MuiTypography>
                            <MuiTypography className='time'>12 minutes ago</MuiTypography>
                        </MuiBox>
                    </MuiBox>

                </MuiBox>

                <MuiBox className='res_notification_footer'>
                    <MuiButton onClick={onClose} endIcon={<ExpandLessIcon />}>Close</MuiButton>
                </MuiBox>
            </MuiBox>

        </Menu>
    )
}

export default NotificationBar