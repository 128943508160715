import { MenuItem, StandardTextFieldProps, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Map } from 'Helpers/Array';
import { GetValue } from 'Helpers/Object';
import { Component, ReactNode } from 'react';

interface TextListProps<T> extends StandardTextFieldProps {
	items: Array<T>;
	valuefield?: string;
	titlefield?: string;
}

export default class MuiListField<T extends {}> extends Component<TextListProps<T>> {
	public render(): ReactNode {
		const { items, titlefield = 'label', valuefield = 'value' }: TextListProps<T> = this.props;
		return (
			<TextField {...this.props} select fullWidth className={'multiSelectFieldMainWrap'}>
				{items && items.length > 0 ? (
					Map(items, (option: T, i) => (
						<MenuItem
							key={i}
							value={GetValue(option, valuefield)}
							sx={[
								(theme: Theme) => ({
									px: 3.5,
									whiteSpace: 'break-spaces',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 2,
									[theme.breakpoints.down("sm")]: {
										minHeight: 35,
									},
									'&:not(:last-child)': {
										mb: '5px',
									},
									'&.Mui-selected': {
										position: 'relative',
										backgroundColor: theme.palette.primary.light,
										'&:hover': {
											backgroundColor: theme.palette.primary.light,
										},
										'&::before': {
											content: '""',
											position: 'absolute',
											top: 13,
											left: 11,
											width: 7,
											height: 12,
											transform: 'rotate(45deg)',
											borderBottom: `2px solid ${theme.palette.primary.main}`,
											borderRight: `2px solid ${theme.palette.primary.main}`,
										},
									},
								})
							]}>
							{GetValue(option, titlefield)}
						</MenuItem>
					))
				) : (
					<MenuItem value="" sx={{ pointerEvents: 'none', color: '#000' }} disabled={true}>
						No Data Found
					</MenuItem>
				)}
			</TextField>
		);
	}
}
