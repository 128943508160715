import { Props, AuthUserModel, StateModel } from './Redux/Models'
import { connect } from 'react-redux';
import { RouteInterface, routes } from './Routes';
import { Filter, Map } from 'Helpers/Array';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import './styles/globals.scss';
import { PrimaryTheme } from './theme/theme';
import createEmotionCache from './theme/createEmotionCache';
import { userTypes } from 'Configs';
import { getUserType } from 'Helpers';

function App(props: Props) {
	const { user, isAuthenticated } = props;
	const isAdmin = (user.role_type && getUserType(user.role_type) === userTypes.ADMIN) ? true : false
	const cache = createEmotionCache()
	return (
		<CacheProvider value={cache}>
			<ThemeProvider theme={PrimaryTheme()}>
				<div className="App">
					<Switch>
						{isAuthenticated && !isAdmin &&
							Map(
								Filter(routes, (item: RouteInterface) => (item.auth === true && item.isAdmin === false)),
								(route: RouteInterface, i: number) => {
									return <Route key={i} exact={route.exact} path={route.path} render={(rProps: any) => <route.component {...props} {...rProps} childrens={route.childrens} />} />;
								}
							)}
						{isAuthenticated && isAdmin &&
							Map(
								Filter(routes, (item: RouteInterface) => item.isAdmin && item.auth),
								(route: RouteInterface, i: number) => {
									return <Route key={i} exact={route.exact} path={route.path} render={(rProps: any) => <route.component {...props} {...rProps} childrens={route.childrens} />} />;
								}
							)}
						{!isAuthenticated &&
							Map(
								Filter(routes, (item: RouteInterface) => !item.auth),
								(route: RouteInterface, i: number) => {
									return <Route key={i} exact={route.exact} path={route.path} render={(rProps: any) => {
										return <route.component {...props} {...rProps} childrens={route.childrens} />
									}
									} />
								}
							)}
					</Switch>
				</div>
			</ThemeProvider>
		</CacheProvider>
	);
}

const mapStateToProps = (state: StateModel) => {
	return {
		isAuthenticated: state.Auth.isAuthenticated,
		user: new AuthUserModel(state.Auth.user),
		deviceToken: state.Auth.deviceToken,
		isExpired: state.Auth.isExpired,
		channel: state.Channel.channel
	};
};

export default connect(mapStateToProps)(App);
