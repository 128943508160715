import { Http, CancelTokenSource, CancelTokenStatic } from '../Http';

export default abstract class BaseService {
	public Http = Http;
	public static cancelToken?: CancelTokenStatic;
	public static source?: CancelTokenSource;

	public static initCancelToken() {
		this.cancelToken = Http.CancelToken;
		this.source = this.cancelToken?.source();
	}

	public static cancelRequestIfPending() {
		this.source?.cancel();
	}
}
