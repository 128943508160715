import BaseService from './BaseService';
import { Http } from '../Http';
import { ChannelModel, Response } from 'Redux/Models';
import { CommonModel, VideoModel } from 'Redux/Models/Video.Models';
import { getSingleVideoData, videoDetailsApiResponse, videoListApiResponse } from 'Configs/types';

export default class YoutubeService extends BaseService {
    public static syncChannel(params: Object = {}): Promise<Response<{ authUrl: string }>> {
        const url = "/youtube-auth"
        return new Promise((resolve, reject) => {
            Http.get(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    public static commonData(params: Object = {}): Promise<Response<CommonModel>> {
        const url = "/common"
        return new Promise((resolve, reject) => {
            Http.get(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    public static uploadVideo(params: Object = {}): Promise<Response<any>> {
        const url = '/upload-video'
        return new Promise((resolve, reject) => {
            Http.post(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    public static VideoList(channel_id=undefined,params: Object = {}): Promise<Response<{videos:videoListApiResponse}>> {
        const url =channel_id? `/videos/${channel_id}`:'/videos'
        return new Promise((resolve, reject) => {
            Http.get(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    public static chanelList(params: Object = {}): Promise<Response<{channels:Array<ChannelModel>}>> {
        const url = '/youtube/channels'
        return new Promise((resolve, reject) => {
            Http.get(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    public static getNewVideo(channelUuid:string,params: Object = {}): Promise<Response<Array<VideoModel>>> {
        const url = '/youtube/videos/'+channelUuid
        return new Promise((resolve, reject) => {
            Http.get(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    //Services for Channel views------------>
    public static getviewsChart(channel_id: string, params: object = {}): Promise<Response<any>> {
        const url = `/youtube/channel/analytics/${channel_id}`;
        return new Promise((resolve, reject) => {
            Http.get(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    public static getVideoAnalysisChart(channel_id: string, VideoId: string, params: object = {}): Promise<Response<any>> {
        const url = `youtube/video/analytics/${channel_id}/${VideoId}`;
        return new Promise((resolve, reject) => {
            Http.get(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    public static getSingleVideoData(VideoId: string, params: object = {}): Promise<Response<getSingleVideoData>> {
        const url = `video/${VideoId}`;
        return new Promise((resolve, reject) => {
            Http.get(url, params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                })
        })
    }


    public static videoDetails(videoId:string,params:object={}):Promise<Response<videoDetailsApiResponse>>{
        const url=`default-video/${videoId}`
        return new Promise((resolve,reject)=>{
            Http.get(url,params).then((res)=>{
                if(res?.data.status===200){
                    return resolve(new Response(res?.data))
                }
            })
            .catch((err)=>{
                return reject(err)
            })
        })
    }

}