import BaseService from './BaseService';
import { Http } from '../Http';
import { Response, UserModel } from 'Redux/Models';
import { Logout,LoadUser, SwithToInitial } from 'Redux/Actions';
import { Dispatch } from '@reduxjs/toolkit';

export default class AuthService extends BaseService {

	public static login(params: Object): Promise<Response<UserModel>> {
		const url = "/register"
		return new Promise((resolve, reject) => {
			Http.post(url, params)
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getMe(params: Object = {}): Promise<Response<UserModel>> {
		const url = '/profile'
		return new Promise((resolve, reject) => {
			Http.get(url, params)
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		})
	}

	public static logOut(payload: Object={}): Function {
		return (dispatch: Dispatch) =>
			new Promise((resolve, reject) => {
				Http.post('/logout', payload)
					.then((res) => {
						if (res?.data.success) {
							dispatch(Logout())
							dispatch(SwithToInitial())
						}
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static updateProfileImage(params: Object): Function {
		return (dispatch: Function) =>
			new Promise((resolve, reject) => {
				Http.post('/profile/image', params)
					.then((res) => {
						if (res?.data.status === 200) {

							dispatch(LoadUser(res?.data.data.user));
						}
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static updateProfile(params:Object):Function{
		return (dispatch: Function) =>
			new Promise((resolve, reject) => {
				Http.post('/profile', params)
					.then((res) => {
						if (res?.data.status === 200) {
							dispatch(LoadUser(res?.data.data.user));
							
						}
						return resolve(new Response(res?.data));
					})
					.catch((err) => {
						return reject(err);
					});
			});
	}

	public static deleteProfileImg(params:Object={}):Function{
		return (dispatch:Function)=>
		new Promise((resolve, reject) => {
			Http.get('/delete/profile/image', params)
				.then((res) => {
					if (res?.data.status === 200) {

						dispatch(LoadUser(res?.data.data.user));
					}
					return resolve(new Response(res?.data));
				})
				.catch((err) => {
					return reject(err);
				});
		});
		
	}

}