import { Component, ComponentType } from 'react';
import loadable from '@loadable/component';
import { AuthLayout, DashboardLayout } from 'Layouts';
import { Fallback } from 'Components';

const LoginView = loadable(() => import('views/Auth/Auth'), {
	fallback: <Fallback />
});

const Activity = loadable(() => import('views/Dashboard/Activity'), {
	fallback: <Fallback />
});

const UploadVideo = loadable(() => import('views/Dashboard/Activity/UploadVideo'), {
	fallback: <Fallback />
});

const VideoAnalysis = loadable(() => import('views/Dashboard/Activity/VideoAnalysis'), {
	fallback: <Fallback />
});

const VideoDetails= loadable(()=> import('views/Dashboard/Videos/VideoDetails'),{
	fallback:<Fallback/>
})

const MyProfile = loadable(() => import('views/Dashboard/MyProfile'), {
	fallback: <Fallback />
});

const Settings = loadable(() => import('views/Dashboard/Settings'), {
	fallback: <Fallback />
});

const ThumbnailAnalysis = loadable(() => import('views/Dashboard/Activity/ThumbnailAnalysis'), {
	fallback: <Fallback />
});

const Home = loadable(() => import('views/Admin/Users'), {
	fallback: <Fallback />
});

const Report = loadable(() => import('views/Admin/Report'), {
	fallback: <Fallback />
});

const UserPlans = loadable(() => import('views/Dashboard/UserPlans'), {
	fallback: <Fallback />
});
const Plans = loadable(() => import('views/Admin/Plans'), {
	fallback: <Fallback />
})
const Videos = loadable(() => import('views/Dashboard/Videos'), {
	fallback: <Fallback />
});

export interface RouteInterface {
	auth?: boolean;
	path: string;
	exact?: boolean;
	name?: string;
	component: ComponentType<any>;
	childrens?: Array<RouteInterface>;
	redirect?: string;
	isAdmin?:boolean;
}

export const routes: Array<RouteInterface> = [
	{
		path: '',
		auth: false,
		exact: true,
		name: 'Login',
		component: AuthLayout,
		childrens: [
			{ path: '/login', exact: true, name: 'Login', component: LoginView },
			{ path: '', redirect: '/login', component: Component }

		]
	},
	{
		path: '',
		auth: true,
		exact: true,
		name: 'Dashboard',
		isAdmin:false,
		component: DashboardLayout,
		childrens: [
			{ path: '/activity', exact: true, name: 'Activity', component: Activity },
			{ path: '/upload-video', exact: true, name: 'Upload Video', component: UploadVideo },
			{ path: '/video-analysis/:id', exact: true, name: 'Video Analysis', component: VideoAnalysis },
			{ path: '/videos/:id', exact: true, name: 'Video Details', component: VideoDetails },
			{ path: '/thumbnail-analysis/:id', exact: true, name: 'Thumbnail Analysis', component: ThumbnailAnalysis },
			{ path: '/profile', exact: true, name: 'Profile', component: MyProfile },
			{ path: '/plans', exact: true, name: 'Plans & Billing', component: UserPlans },
			{ path: '/videos', exact: true, name: 'Videos', component: Videos},
			{ path: '', redirect: '/activity', component: Component }
		]
	},

	{
		path: '',
		auth: true,
		exact: true,
		name: 'Admin',
		isAdmin:true,
		component: DashboardLayout,
		childrens: [
			{ path: '/users', exact: true, name: 'Users', component:Home },
			{ path: '/settings', exact: true, name: 'Settings', component: Settings },
			{ path: '/report', exact: true, name: 'Report', component: Report },
			{ path: '/plans', exact: true, name: 'Plans', component: Plans},
			{ path: '', redirect: '/users', component: Component },
		]
	},
]


