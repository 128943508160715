import { ChannelModel } from "./Channel.Models";
import { AuthUserModel } from "./User.Models";



export abstract class BaseModel {
    [key: string]: any;
    public created_at?: string;
    public updated_at?: string;
    public deleted_at?: string;
}

export abstract class BaseStateModel {
  public errors = {
    has: (e: string): boolean => {
      return false;
    },
    first: (e: string): string => {
      return '';
    },
  };
}

export enum ACTION {
    VIEW,
    EDIT,
    DELETE,
}

type Color = 'inherit' | 'primary' | 'secondary' | 'default';

type Direction = 'asc' | 'desc';


export class InitialAuthStateModel {
    [key: string]: any;
    public token!: string;
    public deviceToken!: string;
    public isAuthenticated!: boolean;
    public isExpired!: boolean;
    public user!: AuthUserModel;
}

export class InitialConfigStateModel {
    [key: string]: any;
    public isToggled!: boolean;
}

export class InitialChannelStateModel{
  [key: string]: any;
  public channel!:ChannelModel
}

export class StateModel {
    public Auth!: InitialAuthStateModel;
    public Config!: InitialConfigStateModel;
    public Channel!:InitialChannelStateModel
}

export class DataTableActionModel {
    public icon!: any;
    public key!: ACTION;
    public color?: Color;
    public tooltip?: string;
    public match?: any;
    public type?:string;
  }


  export class DataTableHeaderModel {
    public label!: string;
    public key!: string;
    public isSort!: boolean;
    public style?: {
      [key: string]: string;
    };
    public render?: Function;
  }
  
export class DataTableModel {
    public url!: string;
    public newBtnTitle?: string;
    public uploadBtnTitle?: string;
    public gridColFull?: boolean;
    public searchable!: boolean;
    public actions!: Array<DataTableActionModel>;
    public headers!: Array<DataTableHeaderModel>;
    public pagination!: boolean;
    public limit!: number;
    public active!: string;
    public direction!: Direction;
    public onActionClick?: Function;
    public onAddClick?: Function;
    public onUploadClick?: Function;
    public filters?: {
      name: string;
      key: string;
      multiple: boolean;
      items: Array<{ label: string; value: string | number }>;
    }[];
  }