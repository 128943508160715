import { Theme, createTheme } from '@mui/material';
import { colors } from './color';

export const PrimaryTheme = () => {
    let theme: Theme = createTheme({
        components: {
            // MuiInputBase: {
            //     defaultProps: {
            //         sx: {
            //             // borderRadius: '30px',
            //         }
            //     }
            // },
            MuiButton: {
                defaultProps: {
                    variant: 'contained',
                    sx: {
                        py: 1,
                        background: `linear-gradient(243.1deg, ${colors.purple} 33.17%, ${colors.btnColor2} 97.17%)`,
                        fontWeight: 600,
                        boxShadow: '0px 2px 24px rgba(82, 14, 226, 0.1)',
                        borderRadius: '100px',
                        padding: '5px 25px',
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: '16px',
                        textTransform: 'none',
                        gap:'5px'
                    }
                }
            },

            MuiTextField: {
                defaultProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {
                        '[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button': {
                            webkitAppearance: 'none',
                            mozAppearance: 'none',
                            appearance: 'none'
                        }
                    }
                }
            },

            MuiLink: {
                defaultProps: {
                    underline: 'none',
                    display: 'inline-block',
                    fontWeight: 500,
                    textTransform: 'none',
                    color: 'info.main',
                    sx: {
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: '18px',
                        transition: '0.5s',
                        '&:hover': {
                            color: 'primary.dark',
                            transition: '0.5s'
                        }
                    }
                }
            }
        }
    });

    return theme;
};