import { ChannelActions, ChannelActionTypes } from "Redux/Actions"
import { InitialChannelStateModel } from "Redux/Models/Base.Models"
import { ChannelModel } from "Redux/Models/Channel.Models"

const initialState={
    channel:new ChannelModel()
}

const Channel=(state=initialState,action:ChannelActions):InitialChannelStateModel=>{
    const {type,payload}=action
    switch (type) {
        case ChannelActionTypes.Channel_Switch:
            return{
                ...state,
                ...payload
            }
        case ChannelActionTypes.Initial_Value:
            return initialState
                
        default:
            return state
    }

}

export default Channel