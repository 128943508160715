import { Map } from "Helpers/Array";
import { Props } from 'Redux/Models';
import { RouteInterface } from "Routes";
import { Redirect, Route, Switch } from "react-router-dom";
import { MuiBox,  MuiGrid } from 'Components/MUI';
import Logo from 'images/Logo.svg'

export default function AuthLayout(props: Props) {
  const { childrens, ...restProps } = props
  return (

    <MuiBox className='auth_body'>
      <MuiGrid container spacing={0} sx={{height:'100%'}}>
        <MuiGrid item lg={6} className='auth_left_grid'>
            <Switch>
              {Map(childrens, (route: RouteInterface, i) => {
                return (
                  <Route
                    key={i}
                    exact={route.exact}
                    path={route.path}
                    render={(rProps: any) =>
                      !route.redirect ? (
                        <route.component
                          {...restProps}
                          {...rProps}
                          childrens={route.childrens}
                        />
                      ) : (
                        <Redirect to={route.redirect || "/"} />
                      )
                    }
                  />
                );
              })}
            </Switch>
        </MuiGrid>
        <MuiGrid item lg={6} className='auth_right_box'>
          <MuiBox className="auth_logo_wraper">
            <img src={Logo} alt="" />
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  )
}
