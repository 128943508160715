import { userTypes,ageRestriction } from "./Constant";
import { SideMenu, AadminSideMenu } from './Menu';

const isDev: boolean = process.env.NODE_ENV === 'development';

const local = 'http://localhost:8000'
const liveURL = 'https://sovereigntyapi.intellexio.com'


export default class Configs {
	public static API_ROOT: string = isDev ? local : liveURL;
	public static API_URL: string = isDev ? `${local}/api/v1` : `${liveURL}/api/v1`
}

// export const googleClientId="575372542888-qfql3glj7pvvvu7be1fkm0ekcbdhufeq.apps.googleusercontent.com"  //Mr. arup
export const googleClientId = "737561518275-5pipjmuvl9jodeup47h3rb3ropnn88r6.apps.googleusercontent.com"
// export const googleClientId='742550029936-15laqu27mcvjhpik92req0bgco3pcius.apps.googleusercontent.com'  //Sourav

export const stripeKey='pk_test_51N0mEpSGphBASCw9cS2a2pvZT0qncDjtJxEC7xFPqusQAiewkk4M5U3TCRUP1q27CEcVxEmFw3rKgGpx65EK31yV00F7WX0lMn'
export const stripeSecretKey='sk_test_51N0mEpSGphBASCw9sp7pEqkr901eU49zfcvk7n907hKcZo4atgAQ7ak8V0Ov98f6NMypg58aHsVFCtud3gYHrcer00PC4M1faG'


export {
	userTypes,
	SideMenu,
	AadminSideMenu,
	ageRestriction
}