import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { MuiBox } from 'Components/MUI';
import { colors } from 'theme/color';

const LoaderWrap = styled(MuiBox)`
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 50%;
    background: #00000040;
    display: flex;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
`;

export default function Fallback() {
  return (
    <LoaderWrap sx={{ display: 'flex' }}>
      <CircularProgress sx={{ color: colors.darkPurple, width: '80px', height: '80px' }} size={100}/>
    </LoaderWrap>
  );
}
