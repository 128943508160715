import { AuthUserModel } from '../Models';
import { Http } from '../../Http';
import { AuthActionTypes, AuthActions } from '../Actions';
import { InitialAuthStateModel } from '../Models/Base.Models';

const initialState: InitialAuthStateModel = {
	token: '',
	isAuthenticated: false,
	isExpired: false,
	user: new AuthUserModel(),
	deviceToken: '',
	rememberMe: false,
	lastLoggedIn: ''
};

const Auth = (state = initialState, action: AuthActions): InitialAuthStateModel => {
	const { type, payload } = action;
	switch (type) {
		case AuthActionTypes.Login:
			Http.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
			return {
				...state,
				...payload,
				isAuthenticated: true,
				isExpired: false,
			};
		case AuthActionTypes.Logout:
			return {
				...state,
				...initialState,
			};

		case AuthActionTypes.LoadUser: {
			return {
				...state,
				user: {
					...state.user,
					...payload
				}
			};
		}

		case AuthActionTypes.LoadDeviceToken: {
			return {
				...state,
				deviceToken: payload
			};
		}

		case AuthActionTypes.CheckAuth:
			if (state.isAuthenticated) {
				Http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
			}
			return state;
		default:
			return state;
	}
};

export default Auth;
