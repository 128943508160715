import { InputAdornment, TextField,TextFieldProps } from '@mui/material';

type Props = {
    starticon?: React.ReactNode;
} & TextFieldProps

const MuiTextField = (props: Props) => {
    const {starticon} = props
    return (
        <>
            <TextField {...props}
                variant="standard"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {starticon}
                        </InputAdornment>
                    ),
                    disableUnderline: true
                }}
            />
           
        </>

    );
};

export default MuiTextField;