import { userTypes } from "Configs";
import { BaseModel } from "./Base.Models";
import { ChannelModel } from "./Channel.Models"
export class UserModel extends BaseModel {
	public constructor(params = {}) {
		super();
		Object.assign(this, params);
	}
	public role_type!: string;
	public email?: string;
	public name?: string;
	public status?: boolean;
	public password?: string;
	public google_token?: string;
	public youtube_handle?: string;
	public profile_image?: string;
	public last_login_at?: string;
	public last_logout_at?: string;
	public is_synced?: boolean;
	public channels: Array<ChannelModel> = []
	public cost?: number;
	public description?: string;
	public subscription?: boolean;

}



export class AuthUserModel extends UserModel {
	public constructor(params = {}) {
		super(params);
		Object.assign(this, params);
	}

	public getUserType = () => {
		const type = this.role_type
		if (parseInt(type) === 1)
			return userTypes.ADMIN
		else if (parseInt(type) === 2)
			return userTypes.USER
		return ""
	}
}