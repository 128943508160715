import { SvgIcon, SvgIconProps } from "@mui/material";
import LogoutIcon from 'images/logout.svg'
import HighIcon from 'images/high_icon.svg'
import MoreIcon from 'images/more_button.svg'
import LikeIcon from 'images/like.svg'
import DisLikeIcon from 'images/dislike.svg'
import DownIcon from 'images/arrow-down.svg'

export const UploadBtnIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Polygon 15" d="M16.6271 10.356C17.7751 11.1514 17.7751 12.8486 16.6271 13.644L3.38899 22.8156C2.06259 23.7346 0.25 22.7853 0.25 21.1716L0.25 2.82836C0.25 1.21472 2.06259 0.265415 3.38899 1.18437L16.6271 10.356Z" fill="#F9F8F8" />
        </SvgIcon>
    );
};

export const ChanelIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
            <path d="m424-373 260-167-260-167v334ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-620h60v620h620v60H140Zm120-740v560-560Z" />
        </SvgIcon>
    );
};

export const TabIconOne = (props: SvgIconProps) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" style={{ marginRight: 10 }}>
            <path d="M250-160q-86 0-148-62T40-370q0-78 49.5-137.5T217-579q20-97 94-158.5T482-799q113 0 189.5 81.5T748-522v24q72-2 122 46.5T920-329q0 69-50 119t-119 50H510q-24 0-42-18t-18-42v-258l-83 83-43-43 156-156 156 156-43 43-83-83v258h241q45 0 77-32t32-77q0-45-32-77t-77-32h-63v-84q0-89-60.5-153T478-739q-89 0-150 64t-61 153h-19q-62 0-105 43.5T100-371q0 62 43.929 106.5Q187.857-220 250-220h140v60H250Zm230-290Z" />
        </SvgIcon>
    );
};

export const TabIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" style={{ marginRight: 10 }}>
            <path d="M450-313v-371L330-564l-43-43 193-193 193 193-43 43-120-120v371h-60ZM220-160q-24 0-42-18t-18-42v-143h60v143h520v-143h60v143q0 24-18 42t-42 18H220Z" />
        </SvgIcon>
    );
};
export const TabIconTwo = (props: SvgIconProps) => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" style={{ marginRight: 10 }}>
            <path d="M457-430h181q24.323 0 41.161-16.773 16.839-16.772 16.839-41Q696-512 679.161-529.5 662.323-547 638-547h-12l-2-10q-4-31-27.776-52-23.775-21-56.467-21Q514-630 492-617q-22 13-30 36l-2 7h-8q-29 0-48.5 21.5T384-502.143q0 28.857 21.5 50.5T457-430ZM100-120q-24 0-42-18t-18-42v-570h60v570h730v60H100Zm120-120q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h260l60 60h320q24 0 42 18t18 42v460q0 24-18 42t-42 18H220Zm0-60h640v-460H515l-60-60H220v520Zm0 0v-520 520Z" />
        </SvgIcon>
    );
};


const InputSearchIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9126 12.6876L11.0251 9.80009C11.8291 8.74454 12.26 7.45195 12.2501 6.1251C12.2551 5.31936 12.1001 4.52064 11.794 3.77527C11.488 3.0299 11.037 2.3527 10.4673 1.78295C9.8975 1.21319 9.2203 0.762218 8.47492 0.456178C7.72955 0.150138 6.93084 -0.0048787 6.1251 0.000117012C5.31936 -0.0048787 4.52064 0.150138 3.77527 0.456178C3.0299 0.762218 2.3527 1.21319 1.78295 1.78295C1.21319 2.3527 0.762218 3.0299 0.456178 3.77527C0.150138 4.52064 -0.0048787 5.31936 0.000117012 6.1251C-0.0048787 6.93084 0.150138 7.72955 0.456178 8.47492C0.762218 9.2203 1.21319 9.8975 1.78295 10.4673C2.3527 11.037 3.0299 11.488 3.77527 11.794C4.52064 12.1001 5.31936 12.2551 6.1251 12.2501C7.45195 12.26 8.74454 11.8291 9.80009 11.0251L12.6876 13.9126L13.9126 12.6876ZM1.75011 6.1251C1.74438 5.54897 1.85363 4.97749 2.07146 4.44411C2.2893 3.91072 2.61134 3.42615 3.01874 3.01874C3.42615 2.61134 3.91072 2.2893 4.44411 2.07146C4.97749 1.85363 5.54897 1.74438 6.1251 1.75011C6.70122 1.74438 7.2727 1.85363 7.80609 2.07146C8.33948 2.2893 8.82405 2.61134 9.23145 3.01874C9.63885 3.42615 9.9609 3.91072 10.1787 4.44411C10.3966 4.97749 10.5058 5.54897 10.5001 6.1251C10.5058 6.70122 10.3966 7.2727 10.1787 7.80609C9.9609 8.33948 9.63885 8.82405 9.23145 9.23145C8.82405 9.63885 8.33948 9.9609 7.80609 10.1787C7.2727 10.3966 6.70122 10.5058 6.1251 10.5001C5.54897 10.5058 4.97749 10.3966 4.44411 10.1787C3.91072 9.9609 3.42615 9.63885 3.01874 9.23145C2.61134 8.82405 2.2893 8.33948 2.07146 7.80609C1.85363 7.2727 1.74438 6.70122 1.75011 6.1251Z" fill="#C1B7DB" />
        </SvgIcon>
    );
};

const NotificationBell = (props: SvgIconProps) => {
    return (
        <SvgIcon width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.8646 14.9479C30.7271 8.4583 25.1417 3.42705 18.5646 2.96038C9.66879 2.31872 2.31879 9.6833 2.96045 18.5646C3.42712 25.1416 8.45837 30.7125 14.948 31.85C16.625 32.1416 18.2584 32.1416 19.8188 31.8791C20.2709 31.8062 20.5334 31.3104 20.3584 30.9021C19.9209 29.9104 19.6875 28.8166 19.6875 27.6937C19.6875 25.2291 20.7813 22.9541 22.7063 21.4375C24.1209 20.3 25.9 19.6729 27.7084 19.6729C28.8459 19.6729 29.925 19.9062 30.9021 20.3437C31.325 20.5333 31.8063 20.2562 31.8938 19.8041C32.1563 18.2583 32.1563 16.625 31.8646 14.9479ZM21.1459 19.7896L19.3959 20.7958L17.6459 21.8021C15.473 23.0562 13.6938 22.0354 13.6938 19.5125V17.5V15.4729C13.6938 12.9646 15.473 11.9291 17.6459 13.1833L19.3959 14.1896L21.1459 15.1958C23.3188 16.4791 23.3188 18.5208 21.1459 19.7896Z" fill="url(#paint0_linear_269_573)" />
            <path d="M27.7083 21.875C26.3375 21.875 25.0688 22.3563 24.0625 23.1583C22.7208 24.2229 21.875 25.8708 21.875 27.7083C21.875 28.8021 22.1812 29.8375 22.7208 30.7125C23.7271 32.4042 25.5792 33.5417 27.7083 33.5417C29.1813 33.5417 30.5229 33.0021 31.5437 32.0833C31.9958 31.7042 32.3896 31.2375 32.6958 30.7125C33.2354 29.8375 33.5417 28.8021 33.5417 27.7083C33.5417 24.4854 30.9313 21.875 27.7083 21.875ZM29.8958 28.7729H28.8021V29.9104C28.8021 30.5083 28.3063 31.0042 27.7083 31.0042C27.1104 31.0042 26.6146 30.5083 26.6146 29.9104V28.7729H25.5208C24.9229 28.7729 24.4271 28.2771 24.4271 27.6792C24.4271 27.0812 24.9229 26.5854 25.5208 26.5854H26.6146V25.55C26.6146 24.9521 27.1104 24.4563 27.7083 24.4563C28.3063 24.4563 28.8021 24.9521 28.8021 25.55V26.5854H29.8958C30.4938 26.5854 30.9896 27.0812 30.9896 27.6792C30.9896 28.2771 30.4938 28.7729 29.8958 28.7729Z" fill="url(#paint1_linear_269_573)" />
            <defs>
                <linearGradient id="paint0_linear_269_573" x1="17.5043" y1="2.92138" x2="-4.86524" y2="14.2734" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
                <linearGradient id="paint1_linear_269_573" x1="27.7083" y1="21.875" x2="18.7584" y2="26.4146" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};

const ActivityMenu = (props: SvgIconProps) => {
    return (
        <SvgIcon width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.4767 3.81759C25.3417 3.26503 25.0668 2.75875 24.6797 2.34989C24.2926 1.94103 23.8071 1.64407 23.2722 1.489C21.3178 1 13.5 1 13.5 1C13.5 1 5.68222 1 3.72778 1.53558C3.19286 1.69064 2.70735 1.9876 2.32029 2.39646C1.93323 2.80532 1.65833 3.3116 1.52335 3.86417C1.16566 5.89651 0.990691 7.95819 1.00065 10.0233C0.987899 12.1039 1.16288 14.1813 1.52335 16.229C1.67216 16.7644 1.95322 17.2514 2.33938 17.643C2.72554 18.0346 3.20374 18.3175 3.72778 18.4644C5.68222 19 13.5 19 13.5 19C13.5 19 21.3178 19 23.2722 18.4644C23.8071 18.3094 24.2926 18.0124 24.6797 17.6035C25.0668 17.1947 25.3417 16.6884 25.4767 16.1358C25.8316 14.1188 26.0065 12.0728 25.9994 10.0233C26.0121 7.94264 25.8371 5.86524 25.4767 3.81759Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 14L17 10L11 6V14Z" fill="#FAF7FC" stroke="#FAF7FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </SvgIcon>
    );
};

const ProfileMenu = (props: SvgIconProps) => {
    return (
        <SvgIcon width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 2.25C10.5525 2.25 8.15625 4.64625 8.15625 7.59375C8.15625 10.485 10.4175 12.825 13.365 12.9262C13.455 12.915 13.545 12.915 13.6125 12.9262C13.635 12.9262 13.6462 12.9262 13.6687 12.9262C13.68 12.9262 13.68 12.9262 13.6913 12.9262C16.5713 12.825 18.8325 10.485 18.8438 7.59375C18.8438 4.64625 16.4475 2.25 13.5 2.25Z" />
            <path d="M19.2151 15.9187C16.0763 13.8262 10.9576 13.8262 7.79633 15.9187C6.36758 16.875 5.58008 18.1687 5.58008 19.5525C5.58008 20.9362 6.36758 22.2187 7.78508 23.1637C9.36008 24.2212 11.4301 24.75 13.5001 24.75C15.5701 24.75 17.6401 24.2212 19.2151 23.1637C20.6326 22.2075 21.4201 20.925 21.4201 19.53C21.4088 18.1462 20.6326 16.8637 19.2151 15.9187Z" />
        </SvgIcon>
    );
};

const SettengsMenu = (props: SvgIconProps) => {
    return (
        <SvgIcon width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.6125 8.37249C18.5763 8.37249 17.7438 6.93249 18.7563 5.16624C19.3413 4.14249 18.9925 2.83749 17.9688 2.25249L16.0225 1.13874C15.1338 0.60999 13.9862 0.92499 13.4575 1.81374L13.3338 2.02749C12.3213 3.79374 10.6563 3.79374 9.6325 2.02749L9.50875 1.81374C9.0025 0.92499 7.855 0.60999 6.96625 1.13874L5.02 2.25249C3.99625 2.83749 3.6475 4.15374 4.2325 5.17749C5.25625 6.93249 4.42375 8.37249 2.3875 8.37249C1.2175 8.37249 0.25 9.32874 0.25 10.51V12.49C0.25 13.66 1.20625 14.6275 2.3875 14.6275C4.42375 14.6275 5.25625 16.0675 4.2325 17.8337C3.6475 18.8575 3.99625 20.1625 5.02 20.7475L6.96625 21.8612C7.855 22.39 9.0025 22.075 9.53125 21.1862L9.655 20.9725C10.6675 19.2062 12.3325 19.2062 13.3562 20.9725L13.48 21.1862C14.0088 22.075 15.1563 22.39 16.045 21.8612L17.9913 20.7475C19.015 20.1625 19.3637 18.8462 18.7787 17.8337C17.755 16.0675 18.5875 14.6275 20.6238 14.6275C21.7938 14.6275 22.7613 13.6712 22.7613 12.49V10.51C22.75 9.33999 21.7938 8.37249 20.6125 8.37249ZM11.5 15.1562C9.48625 15.1562 7.84375 13.5137 7.84375 11.5C7.84375 9.48624 9.48625 7.84374 11.5 7.84374C13.5137 7.84374 15.1562 9.48624 15.1562 11.5C15.1562 13.5137 13.5137 15.1562 11.5 15.1562Z" />
        </SvgIcon>
    );
};

const ButtonAddIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM14 10.75H10.75V14C10.75 14.41 10.41 14.75 10 14.75C9.59 14.75 9.25 14.41 9.25 14V10.75H6C5.59 10.75 5.25 10.41 5.25 10C5.25 9.59 5.59 9.25 6 9.25H9.25V6C9.25 5.59 9.59 5.25 10 5.25C10.41 5.25 10.75 5.59 10.75 6V9.25H14C14.41 9.25 14.75 9.59 14.75 10C14.75 10.41 14.41 10.75 14 10.75Z" fill="url(#paint0_linear_1_362)" />
            <defs>
                <linearGradient id="paint0_linear_1_362" x1="10" y1="-1.66267e-06" x2="-5.34272" y2="7.78216" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};

const PlanAddIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM14 10.75H10.75V14C10.75 14.41 10.41 14.75 10 14.75C9.59 14.75 9.25 14.41 9.25 14V10.75H6C5.59 10.75 5.25 10.41 5.25 10C5.25 9.59 5.59 9.25 6 9.25H9.25V6C9.25 5.59 9.59 5.25 10 5.25C10.41 5.25 10.75 5.59 10.75 6V9.25H14C14.41 9.25 14.75 9.59 14.75 10C14.75 10.41 14.41 10.75 14 10.75Z" fill="#F9F8F8" />
            <defs>
                <linearGradient id="paint0_linear_1_362" x1="10" y1="-1.66267e-06" x2="-5.34272" y2="7.78216" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};



const AnalyzeVideoIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16654 1.65833C5.02487 1.65833 1.6582 5.02499 1.6582 9.16666C1.6582 13.3083 5.02487 16.675 9.16654 16.675C13.3082 16.675 16.6749 13.3083 16.6749 9.16666C16.6749 5.02499 13.3082 1.65833 9.16654 1.65833ZM9.16654 11.0417H6.66654C6.32487 11.0417 6.04154 10.7583 6.04154 10.4167C6.04154 10.075 6.32487 9.79166 6.66654 9.79166H9.16654C9.5082 9.79166 9.79154 10.075 9.79154 10.4167C9.79154 10.7583 9.5082 11.0417 9.16654 11.0417ZM11.6665 8.54166H6.66654C6.32487 8.54166 6.04154 8.25833 6.04154 7.91666C6.04154 7.57499 6.32487 7.29166 6.66654 7.29166H11.6665C12.0082 7.29166 12.2915 7.57499 12.2915 7.91666C12.2915 8.25833 12.0082 8.54166 11.6665 8.54166Z" />
            <path d="M18.3252 15.7917C18.0502 15.2833 17.4669 15 16.6835 15C16.0919 15 15.5835 15.2417 15.2835 15.6583C14.9835 16.075 14.9169 16.6333 15.1002 17.1917C15.4585 18.275 16.0835 18.5167 16.4252 18.5583C16.4752 18.5667 16.5252 18.5667 16.5835 18.5667C16.9502 18.5667 17.5169 18.4083 18.0669 17.5833C18.5085 16.9417 18.5919 16.3 18.3252 15.7917Z" />
            <defs>
                <linearGradient id="paint0_linear_1_400" x1="9.16654" y1="1.65832" x2="-2.35329" y2="7.50143" gradientUnits="userSpaceOnUse">
                    <stop />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_400" x1="16.7378" y1="15" x2="14.0424" y2="16.3334" gradientUnits="userSpaceOnUse">
                    <stop />
                    <stop offset="1" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};


const EditBtnIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 18.3333H2.5C2.15833 18.3333 1.875 18.05 1.875 17.7083C1.875 17.3666 2.15833 17.0833 2.5 17.0833H17.5C17.8417 17.0833 18.125 17.3666 18.125 17.7083C18.125 18.05 17.8417 18.3333 17.5 18.3333Z" />
            <path d="M15.85 2.89999C14.2333 1.28332 12.65 1.24166 10.9916 2.89999L9.98331 3.90832C9.89998 3.99166 9.86665 4.12499 9.89998 4.24166C10.5333 6.44999 12.3 8.21666 14.5083 8.84999C14.5416 8.85832 14.575 8.86666 14.6083 8.86666C14.7 8.86666 14.7833 8.83332 14.85 8.76666L15.85 7.75832C16.675 6.94166 17.075 6.14999 17.075 5.34999C17.0833 4.52499 16.6833 3.72499 15.85 2.89999Z"  />
            <path d="M13.0084 9.60835C12.7668 9.49169 12.5334 9.37502 12.3084 9.24169C12.1251 9.13335 11.9501 9.01669 11.7751 8.89169C11.6334 8.80002 11.4668 8.66669 11.3084 8.53335C11.2918 8.52502 11.2334 8.47502 11.1668 8.40835C10.8918 8.17502 10.5834 7.87502 10.3084 7.54169C10.2834 7.52502 10.2418 7.46669 10.1834 7.39169C10.1001 7.29169 9.95844 7.12502 9.83344 6.93335C9.73344 6.80835 9.61677 6.62502 9.50844 6.44169C9.3751 6.21669 9.25844 5.99169 9.14177 5.75835V5.75835C8.9888 5.43057 8.55859 5.3332 8.30282 5.58897L3.61677 10.275C3.50844 10.3834 3.40844 10.5917 3.38344 10.7334L2.93344 13.925C2.8501 14.4917 3.00844 15.025 3.35844 15.3834C3.65844 15.675 4.0751 15.8334 4.5251 15.8334C4.6251 15.8334 4.7251 15.825 4.8251 15.8084L8.0251 15.3584C8.1751 15.3334 8.38344 15.2334 8.48344 15.125L13.1772 10.4312C13.4279 10.1806 13.3337 9.7493 13.0084 9.60835V9.60835Z"  />
        </SvgIcon>
    );
};

const BtnChartIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4917 1.66663H6.50835C3.47502 1.66663 1.66669 3.47496 1.66669 6.50829V13.4833C1.66669 16.525 3.47502 18.3333 6.50835 18.3333H13.4834C16.5167 18.3333 18.325 16.525 18.325 13.4916V6.50829C18.3334 3.47496 16.525 1.66663 13.4917 1.66663ZM8.25835 13.4916C8.25835 14.025 7.82502 14.4583 7.28335 14.4583C6.75002 14.4583 6.31669 14.025 6.31669 13.4916V10.775C6.31669 10.2416 6.75002 9.80829 7.28335 9.80829C7.82502 9.80829 8.25835 10.2416 8.25835 10.775V13.4916ZM13.6834 13.4916C13.6834 14.025 13.25 14.4583 12.7167 14.4583C12.175 14.4583 11.7417 14.025 11.7417 13.4916V6.50829C11.7417 5.97496 12.175 5.54163 12.7167 5.54163C13.25 5.54163 13.6834 5.97496 13.6834 6.50829V13.4916Z" fill="white" />

        </SvgIcon>
    );
};

const GrowUpIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99999 0.166672C2.77999 0.166672 0.166656 2.78001 0.166656 6.00001C0.166656 9.22001 2.77999 11.8333 5.99999 11.8333C9.21999 11.8333 11.8333 9.22001 11.8333 6.00001C11.8333 2.78001 9.21999 0.166672 5.99999 0.166672ZM8.05916 6.01751C7.97166 6.10501 7.86082 6.14584 7.74999 6.14584C7.63916 6.14584 7.52832 6.10501 7.44082 6.01751L6.43749 5.01417V8.04167C6.43749 8.28084 6.23916 8.47917 5.99999 8.47917C5.76082 8.47917 5.56249 8.28084 5.56249 8.04167V5.01417L4.55916 6.01751C4.38999 6.18667 4.10999 6.18667 3.94082 6.01751C3.77166 5.84834 3.77166 5.56834 3.94082 5.39917L5.69082 3.64917C5.85999 3.48001 6.13999 3.48001 6.30916 3.64917L8.05916 5.39917C8.22832 5.56834 8.22832 5.84834 8.05916 6.01751Z" fill="#40D1E2" />
        </SvgIcon>
    );
};

const GrowDownIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00001 11.8333C9.22001 11.8333 11.8333 9.22001 11.8333 6.00001C11.8333 2.78001 9.22001 0.166675 6.00001 0.166675C2.78001 0.166675 0.166676 2.78001 0.166676 6.00001C0.166676 9.22001 2.78001 11.8333 6.00001 11.8333ZM3.94084 5.98251C4.02834 5.89501 4.13918 5.85418 4.25001 5.85418C4.36084 5.85418 4.47168 5.89501 4.55918 5.98251L5.56251 6.98584L5.56251 3.95834C5.56251 3.71918 5.76084 3.52084 6.00001 3.52084C6.23918 3.52084 6.43751 3.71918 6.43751 3.95834L6.43751 6.98584L7.44084 5.98251C7.61001 5.81334 7.89001 5.81334 8.05918 5.98251C8.22834 6.15168 8.22834 6.43168 8.05918 6.60084L6.30918 8.35084C6.14001 8.52001 5.86001 8.52001 5.69084 8.35084L3.94084 6.60084C3.77168 6.43168 3.77168 6.15168 3.94084 5.98251Z" fill="#E37B73" />
        </SvgIcon>
    );
};

const NextBtnIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 0.25C5.30125 0.25 0.25 5.30125 0.25 11.5C0.25 17.6987 5.30125 22.75 11.5 22.75C17.6987 22.75 22.75 17.6987 22.75 11.5C22.75 5.30125 17.6987 0.25 11.5 0.25ZM14.6388 12.0962L10.6675 16.0675C10.4988 16.2362 10.285 16.315 10.0712 16.315C9.8575 16.315 9.64375 16.2362 9.475 16.0675C9.14875 15.7412 9.14875 15.2013 9.475 14.875L12.85 11.5L9.475 8.125C9.14875 7.79875 9.14875 7.25875 9.475 6.9325C9.80125 6.60625 10.3413 6.60625 10.6675 6.9325L14.6388 10.9038C14.9762 11.23 14.9762 11.77 14.6388 12.0962Z" fill="url(#paint0_linear_1_1136)" />
            <defs>
                <linearGradient id="paint0_linear_1_1136" x1="11.5" y1="0.249998" x2="-5.76056" y2="9.00493" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};

const PrvBtnIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 0.25C17.6988 0.25 22.75 5.30125 22.75 11.5C22.75 17.6987 17.6988 22.75 11.5 22.75C5.30125 22.75 0.25 17.6987 0.25 11.5C0.25 5.30125 5.30125 0.25 11.5 0.25ZM8.36125 12.0962L12.3325 16.0675C12.5012 16.2362 12.715 16.315 12.9288 16.315C13.1425 16.315 13.3562 16.2362 13.525 16.0675C13.8513 15.7412 13.8513 15.2013 13.525 14.875L10.15 11.5L13.525 8.125C13.8513 7.79875 13.8513 7.25875 13.525 6.9325C13.1988 6.60625 12.6587 6.60625 12.3325 6.9325L8.36125 10.9038C8.02375 11.23 8.02375 11.77 8.36125 12.0962Z" fill="#C1B7DB" />
        </SvgIcon>
    );
};

const LinkIconBtn = (props: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99984 0.666626C4.39984 0.666626 0.666504 4.39996 0.666504 8.99996C0.666504 13.6 4.39984 17.3333 8.99984 17.3333C13.5998 17.3333 17.3332 13.6 17.3332 8.99996C17.3332 4.39996 13.5998 0.666626 8.99984 0.666626ZM6.20817 11.3083C6.1165 11.5583 5.87484 11.7166 5.62484 11.7166C5.54984 11.7166 5.48317 11.7083 5.40817 11.675C4.73317 11.425 4.1665 10.9333 3.80817 10.2916C2.97484 8.79163 3.4915 6.83329 4.94984 5.92496L6.89984 4.71663C7.6165 4.27496 8.45817 4.14163 9.25817 4.34996C10.0582 4.55829 10.7332 5.08329 11.1415 5.81663C11.9748 7.31663 11.4582 9.27496 9.99984 10.1833L9.78317 10.3416C9.49984 10.5416 9.10817 10.475 8.90817 10.2C8.70817 9.91663 8.77484 9.52496 9.04984 9.32496L9.30817 9.14163C10.2415 8.55829 10.5582 7.34996 10.0498 6.42496C9.80817 5.99163 9.4165 5.68329 8.94984 5.55829C8.48317 5.43329 7.9915 5.50829 7.5665 5.77496L5.59984 6.99163C4.69984 7.54996 4.38317 8.75829 4.8915 9.69163C5.09984 10.0666 5.43317 10.3583 5.83317 10.5083C6.15817 10.625 6.32484 10.9833 6.20817 11.3083ZM13.0998 12.0416L11.1498 13.25C10.6582 13.5583 10.1082 13.7083 9.54984 13.7083C9.29984 13.7083 9.0415 13.675 8.7915 13.6083C7.9915 13.4 7.3165 12.875 6.9165 12.1416C6.08317 10.6416 6.59984 8.68329 8.05817 7.77496L8.27484 7.61663C8.55817 7.41663 8.94984 7.48329 9.14984 7.75829C9.34984 8.04163 9.28317 8.43329 9.00817 8.63329L8.74984 8.81663C7.8165 9.39996 7.49984 10.6083 8.00817 11.5333C8.24984 11.9666 8.6415 12.275 9.10817 12.4C9.57484 12.525 10.0665 12.45 10.4915 12.1833L12.4415 10.975C13.3415 10.4166 13.6582 9.20829 13.1498 8.27496C12.9415 7.89996 12.6082 7.60829 12.2082 7.45829C11.8832 7.34163 11.7165 6.98329 11.8415 6.65829C11.9582 6.33329 12.3248 6.16663 12.6415 6.29163C13.3165 6.54163 13.8832 7.03329 14.2415 7.67496C15.0665 9.17496 14.5582 11.1333 13.0998 12.0416Z" fill="white" />
        </SvgIcon>
    );
};

const CrossBtnIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99984 0.666626C4.40817 0.666626 0.666504 4.40829 0.666504 8.99996C0.666504 13.5916 4.40817 17.3333 8.99984 17.3333C13.5915 17.3333 17.3332 13.5916 17.3332 8.99996C17.3332 4.40829 13.5915 0.666626 8.99984 0.666626ZM11.7998 10.9166C12.0415 11.1583 12.0415 11.5583 11.7998 11.8C11.6748 11.925 11.5165 11.9833 11.3582 11.9833C11.1998 11.9833 11.0415 11.925 10.9165 11.8L8.99984 9.88329L7.08317 11.8C6.95817 11.925 6.79984 11.9833 6.6415 11.9833C6.48317 11.9833 6.32484 11.925 6.19984 11.8C5.95817 11.5583 5.95817 11.1583 6.19984 10.9166L8.1165 8.99996L6.19984 7.08329C5.95817 6.84163 5.95817 6.44163 6.19984 6.19996C6.4415 5.95829 6.8415 5.95829 7.08317 6.19996L8.99984 8.11663L10.9165 6.19996C11.1582 5.95829 11.5582 5.95829 11.7998 6.19996C12.0415 6.44163 12.0415 6.84163 11.7998 7.08329L9.88317 8.99996L11.7998 10.9166Z" fill="url(#paint0_linear_1_1853)" />
            <defs>
                <linearGradient id="paint0_linear_1_1853" x1="8.99984" y1="0.666625" x2="-3.78577" y2="7.15176" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};


const SaveBtnIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99984 0.666626C4.40817 0.666626 0.666504 4.40829 0.666504 8.99996C0.666504 13.5916 4.40817 17.3333 8.99984 17.3333C13.5915 17.3333 17.3332 13.5916 17.3332 8.99996C17.3332 4.40829 13.5915 0.666626 8.99984 0.666626ZM12.9832 7.08329L8.25817 11.8083C8.1415 11.925 7.98317 11.9916 7.8165 11.9916C7.64984 11.9916 7.4915 11.925 7.37484 11.8083L5.0165 9.44996C4.77484 9.20829 4.77484 8.80829 5.0165 8.56663C5.25817 8.32496 5.65817 8.32496 5.89984 8.56663L7.8165 10.4833L12.0998 6.19996C12.3415 5.95829 12.7415 5.95829 12.9832 6.19996C13.2248 6.44163 13.2248 6.83329 12.9832 7.08329Z" fill="white" />
        </SvgIcon>
    );
};

const PhotoUpload = (props: SvgIconProps) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.1996 4.8C18.4676 4.8 17.7956 4.38 17.4596 3.732L16.5956 1.992C16.0436 0.9 14.6036 0 13.3796 0H10.6316C9.39561 0 7.95561 0.9 7.40361 1.992L6.53961 3.732C6.20361 4.38 5.53161 4.8 4.79961 4.8C2.19561 4.8 0.131615 6.996 0.299615 9.588L0.923615 19.5C1.06762 21.972 2.39962 24 5.71162 24H18.2876C21.5996 24 22.9196 21.972 23.0756 19.5L23.6996 9.588C23.8676 6.996 21.8036 4.8 19.1996 4.8ZM10.1996 6.3H13.7996C14.2916 6.3 14.6996 6.708 14.6996 7.2C14.6996 7.692 14.2916 8.1 13.7996 8.1H10.1996C9.70761 8.1 9.29961 7.692 9.29961 7.2C9.29961 6.708 9.70761 6.3 10.1996 6.3ZM11.9996 19.344C9.76762 19.344 7.94361 17.532 7.94361 15.288C7.94361 13.044 9.75562 11.232 11.9996 11.232C14.2436 11.232 16.0556 13.044 16.0556 15.288C16.0556 17.532 14.2316 19.344 11.9996 19.344Z" fill="white" />
        </SvgIcon>
    );
};


const DeleteBtnIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1417 1.85828C17.8917 1.60828 17.4834 1.60828 17.2334 1.85828L1.8584 17.2416C1.6084 17.4916 1.6084 17.8999 1.8584 18.1499C1.9834 18.2666 2.14173 18.3333 2.3084 18.3333C2.47507 18.3333 2.6334 18.2666 2.7584 18.1416L18.1417 2.75828C18.4001 2.50828 18.4001 2.10828 18.1417 1.85828Z" fill="url(#paint0_linear_1_1882)" />
            <path d="M7.49994 8.65002C8.5953 8.65002 9.48327 7.76205 9.48327 6.66668C9.48327 5.57132 8.5953 4.68335 7.49994 4.68335C6.40457 4.68335 5.5166 5.57132 5.5166 6.66668C5.5166 7.76205 6.40457 8.65002 7.49994 8.65002Z" fill="url(#paint1_linear_1_1882)" />
            <path d="M18.0083 4.4917L16.9917 5.50837C17.0583 5.8167 17.0833 6.15003 17.0833 6.50837V10.5084L16.975 10.4167C16.325 9.85837 15.275 9.85837 14.625 10.4167L11.1583 13.3917C10.5667 13.9 9.63333 13.95 8.98333 13.5167L4.5 18C4.53333 18.0167 4.56667 18.025 4.6 18.0334C4.825 18.1084 5.05 18.1667 5.29167 18.2167C5.375 18.2334 5.45833 18.25 5.55 18.2584C5.85833 18.3 6.175 18.3334 6.50833 18.3334H13.4917C16.525 18.3334 18.3333 16.525 18.3333 13.4917V6.50837C18.3333 5.75837 18.225 5.08337 18.0083 4.4917Z" fill="url(#paint2_linear_1_1882)" />
            <path d="M18.0084 4.4917L16.9917 5.50837C17.0584 5.8167 17.0834 6.15003 17.0834 6.50837V10.5084L16.975 10.4167C16.325 9.85837 15.275 9.85837 14.625 10.4167L11.1584 13.3917C10.5667 13.9 9.63337 13.95 8.98337 13.5167L4.4917 18.0084C5.08337 18.225 5.75837 18.3334 6.50837 18.3334H13.4917C16.525 18.3334 18.3334 16.525 18.3334 13.4917V6.50837C18.3334 5.75837 18.225 5.08337 18.0084 4.4917Z" fill="url(#paint3_linear_1_1882)" />
            <path d="M17.0498 2.94996L16.1665 3.83329C15.5665 3.22496 14.6665 2.91663 13.4915 2.91663H6.50817C4.15817 2.91663 2.9165 4.15829 2.9165 6.50829V13.4916C2.9165 14.125 3.02484 14.6666 3.20817 15.1333L6.32484 13.0416C6.60817 12.85 6.93317 12.75 7.25817 12.7416L2.94984 17.05C2.6165 16.7166 2.3415 16.3166 2.13317 15.8583C1.82484 15.1916 1.6665 14.4 1.6665 13.4916V6.50829C1.6665 3.47496 3.47484 1.66663 6.50817 1.66663H13.4915C15.0082 1.66663 16.2165 2.11663 17.0498 2.94996Z" fill="url(#paint4_linear_1_1882)" />
            <defs>
                <linearGradient id="paint0_linear_1_1882" x1="10.0032" y1="1.67077" x2="-2.78016" y2="8.15558" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_1882" x1="7.49993" y1="4.68335" x2="4.45696" y2="6.22681" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_1882" x1="11.4167" y1="4.4917" x2="0.802002" y2="9.87244" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
                <linearGradient id="paint3_linear_1_1882" x1="11.4125" y1="4.4917" x2="0.794089" y2="9.8776" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
                <linearGradient id="paint4_linear_1_1882" x1="9.35817" y1="1.66662" x2="-2.44294" y2="7.6524" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A925E0" />
                    <stop offset="1" stopColor="#E334CE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};


const UploadPhotoBtn = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4752 0.833374H15.0252C13.9668 0.833374 13.3335 1.46671 13.3335 2.52504V4.97504C13.3335 6.03337 13.9668 6.66671 15.0252 6.66671H17.4752C18.5335 6.66671 19.1668 6.03337 19.1668 4.97504V2.52504C19.1668 1.46671 18.5335 0.833374 17.4752 0.833374ZM17.6585 3.59171C17.5585 3.69171 17.4252 3.74171 17.2918 3.74171C17.1585 3.74171 17.0252 3.69171 16.9252 3.59171L16.7752 3.44171V5.30837C16.7752 5.60004 16.5418 5.83337 16.2502 5.83337C15.9585 5.83337 15.7252 5.60004 15.7252 5.30837V3.44171L15.5752 3.59171C15.3752 3.79171 15.0418 3.79171 14.8418 3.59171C14.6418 3.39171 14.6418 3.05837 14.8418 2.85837L15.8835 1.81671C15.9252 1.77504 15.9835 1.74171 16.0418 1.71671C16.0585 1.70837 16.0752 1.70837 16.0918 1.70004C16.1335 1.68337 16.1752 1.67504 16.2252 1.67504C16.2418 1.67504 16.2585 1.67504 16.2752 1.67504C16.3335 1.67504 16.3835 1.68337 16.4418 1.70837C16.4502 1.70837 16.4502 1.70837 16.4585 1.70837C16.5168 1.73337 16.5668 1.76671 16.6085 1.80837C16.6168 1.81671 16.6168 1.81671 16.6252 1.81671L17.6668 2.85837C17.8668 3.05837 17.8668 3.39171 17.6585 3.59171Z" fill="white" />
            <path d="M7.49994 8.65002C8.5953 8.65002 9.48327 7.76205 9.48327 6.66668C9.48327 5.57132 8.5953 4.68335 7.49994 4.68335C6.40457 4.68335 5.5166 5.57132 5.5166 6.66668C5.5166 7.76205 6.40457 8.65002 7.49994 8.65002Z" fill="white" />
            <path d="M17.4748 6.66663H17.0832V10.5083L16.9748 10.4166C16.3248 9.85829 15.2748 9.85829 14.6248 10.4166L11.1582 13.3916C10.5082 13.95 9.45817 13.95 8.80817 13.3916L8.52484 13.1583C7.93317 12.6416 6.9915 12.5916 6.32484 13.0416L3.20817 15.1333C3.02484 14.6666 2.9165 14.125 2.9165 13.4916V6.50829C2.9165 4.15829 4.15817 2.91663 6.50817 2.91663H13.3332V2.52496C13.3332 2.19163 13.3915 1.90829 13.5248 1.66663H6.50817C3.47484 1.66663 1.6665 3.47496 1.6665 6.50829V13.4916C1.6665 14.4 1.82484 15.1916 2.13317 15.8583C2.84984 17.4416 4.38317 18.3333 6.50817 18.3333H13.4915C16.5248 18.3333 18.3332 16.525 18.3332 13.4916V6.47496C18.0915 6.60829 17.8082 6.66663 17.4748 6.66663Z" fill="white" />
        </SvgIcon>
    );
};


const AddCardDetails = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4752 0.833374H15.0252C13.9668 0.833374 13.3335 1.46671 13.3335 2.52504V4.97504C13.3335 6.03337 13.9668 6.66671 15.0252 6.66671H17.4752C18.5335 6.66671 19.1668 6.03337 19.1668 4.97504V2.52504C19.1668 1.46671 18.5335 0.833374 17.4752 0.833374ZM17.6585 3.59171C17.5585 3.69171 17.4252 3.74171 17.2918 3.74171C17.1585 3.74171 17.0252 3.69171 16.9252 3.59171L16.7752 3.44171V5.30837C16.7752 5.60004 16.5418 5.83337 16.2502 5.83337C15.9585 5.83337 15.7252 5.60004 15.7252 5.30837V3.44171L15.5752 3.59171C15.3752 3.79171 15.0418 3.79171 14.8418 3.59171C14.6418 3.39171 14.6418 3.05837 14.8418 2.85837L15.8835 1.81671C15.9252 1.77504 15.9835 1.74171 16.0418 1.71671C16.0585 1.70837 16.0752 1.70837 16.0918 1.70004C16.1335 1.68337 16.1752 1.67504 16.2252 1.67504C16.2418 1.67504 16.2585 1.67504 16.2752 1.67504C16.3335 1.67504 16.3835 1.68337 16.4418 1.70837C16.4502 1.70837 16.4502 1.70837 16.4585 1.70837C16.5168 1.73337 16.5668 1.76671 16.6085 1.80837C16.6168 1.81671 16.6168 1.81671 16.6252 1.81671L17.6668 2.85837C17.8668 3.05837 17.8668 3.39171 17.6585 3.59171Z" fill="white" />
            <path d="M7.49994 8.65002C8.5953 8.65002 9.48327 7.76205 9.48327 6.66668C9.48327 5.57132 8.5953 4.68335 7.49994 4.68335C6.40457 4.68335 5.5166 5.57132 5.5166 6.66668C5.5166 7.76205 6.40457 8.65002 7.49994 8.65002Z" fill="white" />
            <path d="M17.4748 6.66663H17.0832V10.5083L16.9748 10.4166C16.3248 9.85829 15.2748 9.85829 14.6248 10.4166L11.1582 13.3916C10.5082 13.95 9.45817 13.95 8.80817 13.3916L8.52484 13.1583C7.93317 12.6416 6.9915 12.5916 6.32484 13.0416L3.20817 15.1333C3.02484 14.6666 2.9165 14.125 2.9165 13.4916V6.50829C2.9165 4.15829 4.15817 2.91663 6.50817 2.91663H13.3332V2.52496C13.3332 2.19163 13.3915 1.90829 13.5248 1.66663H6.50817C3.47484 1.66663 1.6665 3.47496 1.6665 6.50829V13.4916C1.6665 14.4 1.82484 15.1916 2.13317 15.8583C2.84984 17.4416 4.38317 18.3333 6.50817 18.3333H13.4915C16.5248 18.3333 18.3332 16.525 18.3332 13.4916V6.47496C18.0915 6.60829 17.8082 6.66663 17.4748 6.66663Z" fill="white" />
        </SvgIcon>
    );
};


const ListCheckIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99984 0.666687C4.40817 0.666687 0.666504 4.40835 0.666504 9.00002C0.666504 13.5917 4.40817 17.3334 8.99984 17.3334C13.5915 17.3334 17.3332 13.5917 17.3332 9.00002C17.3332 4.40835 13.5915 0.666687 8.99984 0.666687ZM12.9832 7.08335L8.25817 11.8084C8.1415 11.925 7.98317 11.9917 7.8165 11.9917C7.64984 11.9917 7.4915 11.925 7.37484 11.8084L5.0165 9.45002C4.77484 9.20835 4.77484 8.80835 5.0165 8.56669C5.25817 8.32502 5.65817 8.32502 5.89984 8.56669L7.8165 10.4834L12.0998 6.20002C12.3415 5.95835 12.7415 5.95835 12.9832 6.20002C13.2248 6.44169 13.2248 6.83335 12.9832 7.08335Z" fill="#C1B7DB" />
        </SvgIcon>
    );
};


const PlanIconBtn = (props: SvgIconProps) => {
    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4667 14.8667L16.1084 15.325C15.7334 15.45 15.4334 15.7417 15.3084 16.125L14.8501 17.4833C14.4584 18.6583 12.8084 18.6333 12.4417 17.4583L10.9001 12.5C10.6001 11.5167 11.5084 10.6 12.4834 10.9083L17.4501 12.45C18.6167 12.8167 18.6334 14.475 17.4667 14.8667Z" fill="#C1B7DB" />
            <path d="M17.5002 9.58332C17.5002 9.94165 17.4752 10.3 17.4335 10.65C17.4002 10.9 17.1418 11.05 16.9002 10.975L12.8585 9.71665C11.9585 9.44165 11.0002 9.67498 10.3335 10.3333C9.66683 11 9.42517 11.975 9.7085 12.875L10.9585 16.9C11.0335 17.1417 10.8752 17.4 10.6252 17.4333C10.2752 17.475 9.9335 17.5 9.5835 17.5C5.1335 17.5 1.55017 13.8416 1.66683 9.35832C1.7835 5.22498 5.22517 1.78332 9.3585 1.66665C13.8418 1.54998 17.5002 5.13332 17.5002 9.58332Z" fill="#C1B7DB" />
        </SvgIcon>
    );
};

const CircleIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.5" cy="7.5" r="6.5" fill="white" stroke="#E37B73" strokeWidth="2" />
        </SvgIcon>
    );
};


const ButtonBlueIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00008 0.666687C4.40841 0.666687 0.666748 4.40835 0.666748 9.00002C0.666748 13.5917 4.40841 17.3334 9.00008 17.3334C13.5917 17.3334 17.3334 13.5917 17.3334 9.00002C17.3334 4.40835 13.5917 0.666687 9.00008 0.666687ZM12.3334 9.62502H9.62508V12.3334C9.62508 12.675 9.34175 12.9584 9.00008 12.9584C8.65842 12.9584 8.37508 12.675 8.37508 12.3334V9.62502H5.66675C5.32508 9.62502 5.04175 9.34169 5.04175 9.00002C5.04175 8.65835 5.32508 8.37502 5.66675 8.37502H8.37508V5.66669C8.37508 5.32502 8.65842 5.04169 9.00008 5.04169C9.34175 5.04169 9.62508 5.32502 9.62508 5.66669V8.37502H12.3334C12.6751 8.37502 12.9584 8.65835 12.9584 9.00002C12.9584 9.34169 12.6751 9.62502 12.3334 9.62502Z" fill="white" />
        </SvgIcon>
    );
};

const NextIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="vuesax/bold/arrow-circle-right">
                <g id="arrow-circle-right">
                    <path id="Vector" d="M13.5 2.25C7.30125 2.25 2.25 7.30125 2.25 13.5C2.25 19.6987 7.30125 24.75 13.5 24.75C19.6987 24.75 24.75 19.6987 24.75 13.5C24.75 7.30125 19.6987 2.25 13.5 2.25ZM16.6388 14.0962L12.6675 18.0675C12.4988 18.2362 12.285 18.315 12.0712 18.315C11.8575 18.315 11.6438 18.2362 11.475 18.0675C11.1487 17.7412 11.1487 17.2013 11.475 16.875L14.85 13.5L11.475 10.125C11.1487 9.79875 11.1487 9.25875 11.475 8.9325C11.8012 8.60625 12.3413 8.60625 12.6675 8.9325L16.6388 12.9038C16.9762 13.23 16.9762 13.77 16.6388 14.0962Z" fill="white" />
                </g>
            </g>
        </SvgIcon>
    );
};


export {
    NextIcon,
    InputSearchIcon,
    NotificationBell,
    ActivityMenu,
    ProfileMenu,
    SettengsMenu,
    LogoutIcon,
    HighIcon,
    MoreIcon,
    ButtonAddIcon,
    AnalyzeVideoIcon,
    EditBtnIcon,
    LikeIcon,
    DisLikeIcon,
    BtnChartIcon,
    GrowUpIcon,
    GrowDownIcon,
    PrvBtnIcon,
    NextBtnIcon,
    LinkIconBtn,
    CrossBtnIcon,
    SaveBtnIcon,
    PhotoUpload,
    DeleteBtnIcon,
    UploadPhotoBtn,
    AddCardDetails,
    ListCheckIcon,
    PlanIconBtn,
    CircleIcon,
    ButtonBlueIcon,
    DownIcon,
    PlanAddIcon,
}