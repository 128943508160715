import { BaseModel } from "./Base.Models";

export class ChannelModel extends BaseModel {
    public constructor(params = {}) {
        super();
        Object.assign(this, params);
    }
    public channel_id?: string;
    public custom_url?: string;
    public default_thumbnail?: string;
    public high_thumbnail?: string;
    public id?: number;
    public medium_thumbnail?: string;
    public name?: string;
    public user_id?: number;
}