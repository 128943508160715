import { ChannelModel } from "Redux/Models";

export enum ChannelActionTypes {
	Channel_Switch = '[Channel_Switch] Action',
    Initial_Value='[Initial_Value] Action'
}

export const ChannelSwitch=(channel:ChannelModel)=>{
    return {
        type:ChannelActionTypes.Channel_Switch,
        payload:{
            channel
        }
    }
}

export const SwithToInitial=()=>{
    return {
        type:ChannelActionTypes.Initial_Value,
       
    }
}

export class ChannelActions {
	type!: ChannelActionTypes;
	payload!: any;
}
